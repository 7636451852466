import CardMedia                                  from '@mui/material/CardMedia'
import { Link }                                   from 'gatsby'
import React                                      from 'react'
import { ImageCard, ImageGrid, ImageTextOverlay } from '../components'

const YearContent = ({ items }) => (
  <ImageGrid>
    {items.map(({ year: currentYear, month, total, item: { original, small } }) => (
      <ImageCard key={original.key}>
        <Link
          to={`/media/${currentYear}/${month.slice(0, 2)}`}
          aria-label={`go to images for ${month} ${currentYear}`}
        >
          <CardMedia
            className='lazyload'
            data-sizes='auto'
            component='img'
            src={small.url}
            alt={`Image for ${currentYear} ${month}`}
            title={`Image for ${currentYear} ${month}`}
            aria-label={`Image for ${currentYear} ${month}`}
          />
          <ImageTextOverlay>
            <h1>{`${month.substring(3)} ${currentYear}`}</h1>
            <span>{`${total} images`}</span>
          </ImageTextOverlay>
        </Link>
      </ImageCard>
    ))}
  </ImageGrid>
)

export { YearContent }
