import { graphql, navigate }               from 'gatsby'
import React, { useRef }                   from 'react'
import { Footer, Head, PageContainerGrid } from '../components'
import { LinksProvider, YearsProvider }    from '../context'
import { Header }                          from '../features'
import { yearNavigationLinks }             from '../services/media-utils'

import { YearContent }                     from './year-content'

const YearTemplate = ({
  pageContext: { years, year },
  data: {
    api: {
      imagesByMonthForYear: { items },
    },
  },
}) => {
  const tRef = useRef(null)
  const yearIndex = years ? years.findIndex(y => y.year === year) : null
  const links = yearNavigationLinks(yearIndex, years)

  return (
    <YearsProvider value={{ years }}>
      <Head title={year.toString()} />
      <LinksProvider value={{ links, navigate }}>
        <PageContainerGrid ref={tRef}>
          <Header />
          <main>
            <YearContent items={items} />
          </main>
          <Footer tRef={tRef} />
        </PageContainerGrid>
      </LinksProvider>
    </YearsProvider>
  )
}

export const query = graphql`
  query ($year: Int!) {
    api {
      imagesByMonthForYear(year: $year) {
        items {
          year
          month
          total
          item {
            original {
              key
            }
            small {
              key
              url
            }
          }
        }
      }
    }
  }
`

export default YearTemplate
